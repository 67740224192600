import PropTypes from 'prop-types';
import React, { useState } from 'react';

const MONTH_NAMES = [
    'J',
    'F',
    'M',
    'A',
    'M',
    'J',
    'J',
    'A',
    'S',
    'O',
    'N',
    'D',
];

const getNextDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

const getDatesForYear = (year) => {
    const yearStart = new Date(year, 0, 1);
    const nextYearStart = new Date(year + 1, 0, 1);
    const dates = [];
    for (let d = yearStart; d < nextYearStart; d = getNextDay(d)) {
        dates.push(d);
    }
    return dates;
}

const MonthHeader = ({ name, index }) => {
    const classNames = `yv-item yv-month-${index} yv-label`;
    return (
        <div className={classNames}>{name}</div>
    );
};

const DayButton = ({ date }) => {
    const [done, setDone] = useState(false);

    const onClick = () => setDone(!done);

    const today = new Date();
    const isDateAfterToday = date > today;
    const isDateInPast = date < today;
    const isToday = date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear()

    const classNames = 'day-button'
        + (done ? ' done' : '')
        + (isDateInPast ? ' in-past' : '')
        + (isToday ? ' is-today' : '');
    return (
        <button
            className={classNames}
            disabled={isDateAfterToday}
            onClick={onClick}
        >
            {date.getDate()}
        </button>
    );
}

const DayItem = ({ date }) => {
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const classNames = `yv-item yv-month-${monthIndex} yv-day-${day}`;
    return (
        <div className={classNames}>
            <DayButton date={date} />
        </div>
    );
};




const YearView = ({ year }) => {
    const dates = getDatesForYear(year)
    return (
        <div className='yv-container'>
            {MONTH_NAMES.map((name, i) => <MonthHeader key={i} name={name} index={i} />)}
            {dates.map((date, i) => <DayItem key={i} date={date} />)}
        </div>
    );
}

YearView.propTypes = {
    year: PropTypes.number.isRequired
}

export default YearView;