import './App.scss';
import YearView from './year-view';

function App() {
  return (
    <div className="App">
      <YearView year={2022}/>
    </div>
  );
}

export default App;
